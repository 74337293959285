/* eslint-disable no-undefined */
import {
  AccountDetailsResponse,
  CommissionSummaryResponse,
  SlotsResponse,
} from "../Services/SalesforceApi";
import { Lineage } from "../Services/SalesforceApi/Lineage";
import {
  AnyLevelSlot,
  BonusLevel,
  Member,
  MemberWithTv,
  OneLevelSlot,
  ThreeLevelSlot,
  TrackerSlots,
  TwoLevelSlot,
} from "../types";
import { shortDate } from "./date";

export const isOneLevelSlot = (slot: AnyLevelSlot): slot is OneLevelSlot => {
  return !isTwoLevelSlot(slot) && !isThreeLevelSlot(slot);
};

export const isTwoLevelSlot = (slot: AnyLevelSlot): slot is TwoLevelSlot => {
  return "slots" in slot && !isThreeLevelSlot(slot);
};

export const isThreeLevelSlot = (
  slot: AnyLevelSlot
): slot is ThreeLevelSlot => {
  return "slots" in slot && "slots" in slot.slots[0];
};

export function mapApiSlotsToTrackerSlots(
  bonusLevel: BonusLevel,
  userDoterraId: string,
  tabNumber: number,
  slots: SlotsResponse,
  commissionSummaries: CommissionSummaryResponse,
  accountDetails: AccountDetailsResponse
): {
  main: TrackerSlots;
  backup: TrackerSlots;
} {
  const PV_TARGET = 100;
  const TV_TARGET = 100;
  const MAIN_SLOTS_COUNT = 3;
  const BACKUP_SLOTS_MAX = 10;

  const getMemberAtPosition = (
    firstLevelSlotNumber: number,
    secondLevelSlotNumber: number | undefined,
    thirdLevelSlotNumber: number | undefined,
    includeTv: boolean
  ) => {
    const slot = trackerSlots.find((s) => {
      const lineage = Lineage.decode(s.lineage);
      return (
        lineage.firstLevelSlotNumber === firstLevelSlotNumber &&
        lineage.secondLevelSlotNumber === secondLevelSlotNumber &&
        lineage.thirdLevelSlotNumber === thirdLevelSlotNumber
      );
    });

    if (!slot) {
      return undefined;
    }

    const commissionSummary = commissionSummaries.find(
      (summary) => summary.accountId === slot.doterraId
    );
    const accountDetail = accountDetails.find(
      (account) => account.id === slot.doterraId
    );

    return {
      firstName: accountDetail?.firstName,
      lastName: accountDetail?.lastName,
      lrpNeeded: PV_TARGET,
      lrpProcessed: commissionSummary?.lrpPv ?? 0,
      lrpScheduled: commissionSummary?.scheduledLrpPv,
      lrpScheduledDate: shortDate(commissionSummary?.nextLRPRunDate),
      tvNeeded: includeTv ? TV_TARGET : undefined,
      tvProcessed: includeTv ? commissionSummary?.tv : undefined,
    } as MemberWithTv | Member;
  };

  const getOneLevelSlot = (slotNumber: number) => {
    return {
      member: getMemberAtPosition(
        slotNumber,
        undefined,
        undefined,
        false
      ) as Member,
    } as OneLevelSlot;
  };

  const getTwoLevelSlot = (slotNumber: number) => {
    return {
      member: getMemberAtPosition(
        slotNumber,
        undefined,
        undefined,
        true
      ) as MemberWithTv,
      slots: [
        {
          member: getMemberAtPosition(
            slotNumber,
            0,
            undefined,
            false
          ) as Member,
        },
        {
          member: getMemberAtPosition(
            slotNumber,
            1,
            undefined,
            false
          ) as Member,
        },
        {
          member: getMemberAtPosition(
            slotNumber,
            2,
            undefined,
            false
          ) as Member,
        },
      ],
    } as TwoLevelSlot;
  };

  const getThreeLevelSlot = (slotNumber: number) => {
    return {
      member: getMemberAtPosition(
        slotNumber,
        undefined,
        undefined,
        true
      ) as MemberWithTv,
      slots: [
        {
          member: getMemberAtPosition(
            slotNumber,
            0,
            undefined,
            true
          ) as MemberWithTv,
          slots: [
            {
              member: getMemberAtPosition(slotNumber, 0, 0, false) as Member,
            },
            {
              member: getMemberAtPosition(slotNumber, 0, 1, false) as Member,
            },
            {
              member: getMemberAtPosition(slotNumber, 0, 2, false) as Member,
            },
          ],
        },
        {
          member: getMemberAtPosition(
            slotNumber,
            1,
            undefined,
            true
          ) as MemberWithTv,
          slots: [
            {
              member: getMemberAtPosition(slotNumber, 1, 0, false) as Member,
            },
            {
              member: getMemberAtPosition(slotNumber, 1, 1, false) as Member,
            },
            {
              member: getMemberAtPosition(slotNumber, 1, 2, false) as Member,
            },
          ],
        },
        {
          member: getMemberAtPosition(
            slotNumber,
            2,
            undefined,
            true
          ) as MemberWithTv,
          slots: [
            {
              member: getMemberAtPosition(slotNumber, 2, 0, false) as Member,
            },
            {
              member: getMemberAtPosition(slotNumber, 2, 1, false) as Member,
            },
            {
              member: getMemberAtPosition(slotNumber, 2, 2, false) as Member,
            },
          ],
        },
      ],
    } as ThreeLevelSlot;
  };

  const trackerSlots = slots
    .filter((slot) => slot.doterraId !== userDoterraId)
    .filter((slot) => slot.tab === tabNumber);

  let mainSlots: TrackerSlots = Array.from({ length: MAIN_SLOTS_COUNT });
  let backupSlots: TrackerSlots = Array.from({ length: BACKUP_SLOTS_MAX });

  switch (bonusLevel) {
    case 1:
      mainSlots = mainSlots.map((_, i) => getOneLevelSlot(i));
      backupSlots = backupSlots.map((_, i) =>
        getOneLevelSlot(i + MAIN_SLOTS_COUNT)
      );
      break;
    case 2:
      mainSlots = mainSlots.map((_, i) => getTwoLevelSlot(i));
      backupSlots = backupSlots.map((_, i) =>
        getTwoLevelSlot(i + MAIN_SLOTS_COUNT)
      );
      break;
    case 3:
      mainSlots = mainSlots.map((_, i) => getThreeLevelSlot(i));
      backupSlots = backupSlots.map((_, i) =>
        getThreeLevelSlot(i + MAIN_SLOTS_COUNT)
      );
      break;
    default:
      throw new Error("Bonus level out of range.");
  }

  const numberOfPopulatedBackupSlots = backupSlots.filter(
    (slot) => slot.member
  ).length;
  backupSlots = backupSlots.slice(0, numberOfPopulatedBackupSlots + 1);

  return {
    main: mainSlots,
    backup: backupSlots,
  };
}
