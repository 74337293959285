import * as i18n from "i18next";
import HttpBackend, { HttpBackendOptions } from "i18next-http-backend";
import { initReactI18next } from "react-i18next";
import { env } from "./Utilities";
import { parseCrownPeakResponse } from "./Utilities/crownpeak";

const crownpeakBackend = {
  loadPath:
    "https://searchg2.crownpeak.net/doterra-misc-dev/select?q=*&wt=json&indent=true&fq=custom_s_app_name:power-of-three.{{ns}}.{{lng}}&fl=content",
  parse: (r: string) => parseCrownPeakResponse(r),
};

// Local backend used for testing.
// const localBackend = {
//   loadPath: "/locales/{{lng}}/{{ns}}.json",
// };

void i18n
  .use(initReactI18next)
  .use(HttpBackend)
  .init<HttpBackendOptions>({
    debug: env("NODE_ENV") === "development",
    ns: ["application", "components"],
    defaultNS: "application",
    fallbackLng: "en_US",
    load: "currentOnly",
    backend: {
      ...crownpeakBackend,
    },
    interpolation: {
      escapeValue: false,
    },
  });

export default i18n;
