import {
  Po3CmsContent,
  Po3CmsContentProvider,
} from "@doterra-design-system/leader-tools-components/Po3";
import React, { ReactNode } from "react";
import { useTranslation } from "react-i18next";

interface Po3ComponentsI18nProviderProps {
  children: ReactNode;
}

export const Po3ComponentsI18nProvider = ({
  children,
}: Po3ComponentsI18nProviderProps) => {
  const { i18n } = useTranslation();
  const [currentLanguage] = i18n.languages;
  const po3CmsContent = i18n.getResourceBundle(
    currentLanguage,
    "components"
  ) as Po3CmsContent;

  return (
    <Po3CmsContentProvider data={po3CmsContent}>
      {children}
    </Po3CmsContentProvider>
  );
};
