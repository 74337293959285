type CrownpeakContent = {
  [key: string]: any;
};

type CrownpeakResponse = {
  response: {
    docs: {
      content: string;
    }[];
  };
};

export function parseCrownPeakResponse(responseStr: string): CrownpeakContent {
  try {
    const response = JSON.parse(responseStr) as CrownpeakResponse;

    if (
      response &&
      response.response &&
      Array.isArray(response.response.docs) &&
      response.response.docs.length > 0
    ) {
      const contentString = response.response.docs[0]?.content;

      if (contentString) {
        return JSON.parse(contentString) as CrownpeakContent;
      }
    }

    throw new Error("Invalid response or content");
  } catch (error) {
    console.error("Error parsing response or content as JSON:", error);
    return {};
  }
}
