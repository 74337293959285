/* eslint-disable no-undefined */
import { usePrevious } from "@doterra-design-system/design-system";
import {
  keepPreviousData,
  useQuery,
  useQueryClient,
} from "@tanstack/react-query";
import { useCallback, useEffect, useState } from "react";
import { Slot } from "../Services/SalesforceApi";
import { Lineage } from "../Services/SalesforceApi/Lineage";
import { queryKeys } from "../Utilities";
import { transformCommissionSummaries } from "../Utilities/comission";
import { mapApiSlotsToTrackerSlots } from "../Utilities/tracker";
import { BonusLevel, MonthNumber, Tracker } from "../types";
import { useSalesforceApi } from "./useSalesforceApi";
import { useUser } from "./useUser";

const PV_TARGET = 100;
const TV_TARGET = 100;

export function useTracker(
  month: MonthNumber,
  year: number,
  trackerNumber: number
) {
  const api = useSalesforceApi();
  const user = useUser();
  const client = useQueryClient();
  const [numberOfTrackers, setNumberOfTrackers] = useState<number>(1);
  const [canAddTracker, setCanAddTracker] = useState<boolean>(false);
  const previousMonth = usePrevious(month);
  const [isLoadingAnotherMonth, setIsLoadingAnotherMonth] =
    useState<boolean>(false);
  const [legacyTabNumberOffset, setLegacyTabNumberOffset] = useState<number>(0);

  const tabNumber = trackerNumber + legacyTabNumberOffset - 1;

  const {
    data: slots,
    isLoading: slotsIsLoading,
    isFetching: slotsIsFetching,
  } = useQuery({
    queryKey: queryKeys.slotsByTimePeriod(month, year),
    queryFn: () => api.slots(month, year),
    placeholderData: keepPreviousData,
  });

  const doterraIds = slots?.map((slot) => slot.doterraId) ?? [];
  const trackerDoterraIds =
    slots
      ?.filter((slot) => {
        return slot.tab === tabNumber;
      })
      .map((slot) => slot.doterraId) ?? [];

  const {
    data: commissionSummaries,
    isLoading: commissionSummariesIsLoading,
    isFetching: commissionSummariesIsFetching,
  } = useQuery({
    queryKey: queryKeys.commissionSummaries(month, year, doterraIds),
    queryFn: () => api.commissionSummary(month, year, doterraIds),
    enabled: Boolean(doterraIds.length),
    placeholderData: keepPreviousData,
    select: transformCommissionSummaries,
  });

  const {
    data: accountDetails,
    isLoading: accountDetailsIsLoading,
    isFetching: accountDetailsIsFetching,
  } = useQuery({
    queryKey: queryKeys.accountDetails(doterraIds),
    queryFn: () => api.accountDetails(doterraIds),
    enabled: Boolean(doterraIds.length),
    placeholderData: keepPreviousData,
  });

  const isLoading =
    slotsIsLoading || commissionSummariesIsLoading || accountDetailsIsLoading;
  const isFetching =
    slotsIsFetching ||
    commissionSummariesIsFetching ||
    accountDetailsIsFetching;

  const [isUpdating, setIsUpdating] = useState(false);

  const selfSlot = slots?.find(
    (slot) => slot.doterraId === user.doterraId && slot.tab === tabNumber
  );
  const selfCommissionSummary = commissionSummaries?.find(
    (summary) => summary.accountId === user.doterraId
  );

  const addDefaultSelfSlot = useCallback(async () => {
    const newSlot: Slot = {
      tab: tabNumber,
      month: month.toString(),
      year: year.toString(),
      ownerDoterraId: user.doterraId,
      lineage: Lineage.encode({
        ownerDoterraId: user.doterraId,
        month,
        year,
        tabNumber,
      }),
      isCollapsed: false,
      doterraId: user.doterraId,
      bonusLevel: "1",
    };

    setIsUpdating(true);

    await api.updateSlot(newSlot);

    await client.invalidateQueries({
      queryKey: queryKeys.slotsByTimePeriod(month, year),
    });

    setIsUpdating(false);
  }, [api, client, month, tabNumber, user.doterraId, year]);

  useEffect(() => {
    if (!slotsIsLoading && selfSlot === undefined) {
      void addDefaultSelfSlot();
    }
  }, [slotsIsLoading, selfSlot, addDefaultSelfSlot]);

  useEffect(() => {
    if (!slots || slots.length === 0) {
      return;
    }

    const lowestTabNumber = slots?.reduce((lowest, slot) => {
      return slot.tab < lowest ? slot.tab : lowest;
    }, 100);

    setLegacyTabNumberOffset(lowestTabNumber ?? 0);
  }, [slots]);

  useEffect(() => {
    const selfSlots = slots?.filter(
      (slot) => user.doterraId === slot.doterraId
    );
    const numTrackers = selfSlots?.length || 1;
    const maxTrackers =
      Math.floor((selfCommissionSummary?.po3Rank ?? 0) / 3) + 1;

    setNumberOfTrackers(numTrackers);
    setCanAddTracker(numTrackers < maxTrackers);
  }, [slots, user, selfCommissionSummary]);

  useEffect(() => {
    if (previousMonth && month !== previousMonth) {
      setIsLoadingAnotherMonth(isFetching);
    }

    if (!isFetching) {
      setIsLoadingAnotherMonth(false);
    }
  }, [month, previousMonth, isFetching]);

  if (!slots || !commissionSummaries || !accountDetails) {
    return null;
  }

  const bonusLevel: BonusLevel = selfSlot
    ? (parseInt(selfSlot.bonusLevel, 10) as BonusLevel)
    : 1;

  const trackerSlots = mapApiSlotsToTrackerSlots(
    bonusLevel,
    user.doterraId,
    tabNumber,
    slots,
    commissionSummaries,
    accountDetails
  );

  const trackerData: Tracker = {
    bonusLevel,
    personalContribution: {
      lrpPv: selfCommissionSummary?.lrpPv ?? 0,
      lrpPvTarget: PV_TARGET,
      lrpPvScheduled: selfCommissionSummary?.scheduledLrpPv ?? 0,
      lrpPvScheduledDate: selfCommissionSummary?.nextLRPRunDate ?? null,
      tv: selfCommissionSummary?.tv ?? 0,
      tvTarget: TV_TARGET,
    },
    slots: trackerSlots.main,
    backupSlots: trackerSlots.backup,
    usedAccountIds: trackerDoterraIds,
  };

  const changeBonusLevel = async (newBonusLevel: BonusLevel) => {
    let updatedSelfSlot = selfSlot;

    if (!updatedSelfSlot) {
      updatedSelfSlot = {
        tab: tabNumber,
        month: month.toString(),
        year: year.toString(),
        ownerDoterraId: user.doterraId,
        lineage: Lineage.encode({
          ownerDoterraId: user.doterraId,
          month,
          year,
          tabNumber,
        }),
        isCollapsed: false,
        doterraId: user.doterraId,
        bonusLevel: newBonusLevel.toString(),
      };
    } else {
      updatedSelfSlot.bonusLevel = newBonusLevel.toString();
    }

    setIsUpdating(true);

    await api.updateSlot(updatedSelfSlot);

    void client.invalidateQueries({
      queryKey: queryKeys.slotsByTimePeriod(month, year),
    });

    setIsUpdating(false);
  };

  const clearAllForSlot = async (slotIndex: number) => {
    const slotsToClear = slots
      .filter((slot) => {
        const lineage = Lineage.decode(slot.lineage);
        return (
          lineage.firstLevelSlotNumber === slotIndex && slot.tab === tabNumber
        );
      })
      .map((slot) => {
        slot.isActive = false;
        return slot;
      });

    await api.updateSlots(slotsToClear);

    setIsUpdating(true);

    void client.invalidateQueries({
      queryKey: queryKeys.slotsByTimePeriod(month, year),
    });

    setIsUpdating(false);
  };

  const setSlot = async (
    doterraId: string,
    firstLevelSlotNumber?: number,
    secondLevelSlotNumber?: number,
    thirdLevelSlotNumber?: number
  ) => {
    const slot: Slot = {
      tab: tabNumber,
      month: month.toString(),
      year: year.toString(),
      ownerDoterraId: user.doterraId,
      lineage: Lineage.encode({
        ownerDoterraId: user.doterraId,
        month,
        year,
        tabNumber,
        firstLevelSlotNumber,
        secondLevelSlotNumber,
        thirdLevelSlotNumber,
      }),
      isCollapsed: false,
      doterraId,
      bonusLevel: "1",
    };

    setIsUpdating(true);

    client.setQueryData(
      queryKeys.slotsByTimePeriod(month, year),
      (oldSlots: Slot[]) => {
        const existingSlot = oldSlots.find((oldSlot) => {
          return (
            oldSlot.lineage === slot.lineage &&
            oldSlot.tab === slot.tab &&
            slot.doterraId === oldSlot.doterraId
          );
        });

        if (existingSlot) {
          return oldSlots.map((oldSlot) => {
            if (
              oldSlot.lineage === slot.lineage &&
              oldSlot.doterraId === slot.doterraId &&
              oldSlot.tab === slot.tab
            ) {
              return slot;
            }

            return oldSlot;
          });
        }

        return [...oldSlots, slot];
      }
    );

    await api.updateSlot(slot);

    void client.invalidateQueries({
      queryKey: queryKeys.slotsByTimePeriod(month, year),
    });

    setIsUpdating(false);
  };

  const getParentSlotAtPosition = (
    firstLevelSlotNumber?: number,
    secondLevelSlotNumber?: number,
    thirdLevelSlotNumber?: number
  ) => {
    if (thirdLevelSlotNumber !== undefined) {
      return slots.find((slot) => {
        const lineage = Lineage.decode(slot.lineage);
        return (
          lineage.firstLevelSlotNumber === firstLevelSlotNumber &&
          lineage.secondLevelSlotNumber === secondLevelSlotNumber &&
          lineage.thirdLevelSlotNumber === undefined &&
          slot.tab === tabNumber
        );
      });
    }

    if (secondLevelSlotNumber !== undefined) {
      return slots.find((slot) => {
        const lineage = Lineage.decode(slot.lineage);
        return (
          lineage.firstLevelSlotNumber === firstLevelSlotNumber &&
          lineage.secondLevelSlotNumber === undefined &&
          lineage.thirdLevelSlotNumber === undefined &&
          slot.tab === tabNumber
        );
      });
    }

    return undefined;
  };

  const getSlotAtPosition = (
    firstLevelSlotNumber?: number,
    secondLevelSlotNumber?: number,
    thirdLevelSlotNumber?: number
  ) => {
    return slots.find((slot) => {
      const lineage = Lineage.decode(slot.lineage);
      return (
        lineage.firstLevelSlotNumber === firstLevelSlotNumber &&
        lineage.secondLevelSlotNumber === secondLevelSlotNumber &&
        lineage.thirdLevelSlotNumber === thirdLevelSlotNumber &&
        slot.tab === tabNumber
      );
    });
  };

  const addTracker = async () => {
    const highestTabNumber = slots.reduce((highest, slot) => {
      return slot.tab > highest ? slot.tab : highest;
    }, 0);
    const newTrackerNumber = highestTabNumber + 1;

    const newSlot: Slot = {
      tab: newTrackerNumber,
      month: month.toString(),
      year: year.toString(),
      ownerDoterraId: user.doterraId,
      lineage: Lineage.encode({
        ownerDoterraId: user.doterraId,
        month,
        year,
        tabNumber: newTrackerNumber,
      }),
      isCollapsed: false,
      doterraId: user.doterraId,
      bonusLevel: "1",
    };

    setIsUpdating(true);

    client.setQueryData(
      queryKeys.slotsByTimePeriod(month, year),
      (oldSlots: Slot[]) => {
        return [...oldSlots, newSlot];
      }
    );

    await api.updateSlot(newSlot);

    await client.invalidateQueries({
      queryKey: queryKeys.slotsByTimePeriod(month, year),
    });

    setIsUpdating(false);
  };

  const deleteTab = async (deletingTabNumber: number) => {
    setIsUpdating(true);
    const tabToDelete = deletingTabNumber + legacyTabNumberOffset - 1;

    // Deactivate the slots in the active tracker
    const slotsToDisable = slots.filter((slot) => {
      const lineage = Lineage.decode(slot.lineage);

      // Protect the first tracker self slot, if it's the only one remaining
      if (
        lineage.tabNumber === 0 &&
        slot.doterraId === user.doterraId &&
        slots.filter((s) => s.doterraId === user.doterraId).length === 1
      ) {
        return false;
      }

      return lineage.tabNumber === tabToDelete;
    });
    await api.deleteSlots(slotsToDisable);

    const slotsInAHigherTabThanDeleted = slots.filter((slot) => {
      const lineage = Lineage.decode(slot.lineage);
      return lineage.tabNumber > tabToDelete;
    });

    // Shift all of the slots in higher tabs down a tab
    const slotsToShiftDownATab = slotsInAHigherTabThanDeleted.map((slot) => {
      const modifiedSlot = { ...slot };
      modifiedSlot.tab = modifiedSlot.tab - 1;
      modifiedSlot.lineage = Lineage.encode({
        ...Lineage.decode(modifiedSlot.lineage),
        tabNumber: modifiedSlot.tab,
      });
      return modifiedSlot;
    });
    await api.updateSlots(slotsToShiftDownATab);

    // Delete the original slots in a higher tab, since they get copied rather than moved
    await api.deleteSlots(slotsInAHigherTabThanDeleted);

    await client.invalidateQueries({
      queryKey: queryKeys.slotsByTimePeriod(month, year),
    });

    setIsUpdating(false);
  };

  const resetTracker = async () => {
    setIsUpdating(true);

    const slotsToReset = slots.map((slot) => {
      const modifiedSlot = { ...slot };
      modifiedSlot.isActive = false;
      return modifiedSlot;
    });

    await api.updateSlots(slotsToReset);
    await addDefaultSelfSlot();

    await client.invalidateQueries({
      queryKey: queryKeys.slotsByTimePeriod(month, year),
    });

    setIsUpdating(false);
  };

  return {
    data: trackerData,
    setSlot,
    clearAllForSlot,
    changeBonusLevel,
    getSlotAtPosition,
    getParentSlotAtPosition,
    isLoading,
    isLoadingAnotherMonth,
    isUpdating,
    numberOfTrackers,
    canAddTracker,
    addTracker,
    deleteTab,
    resetTracker,
  };
}
